
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/projectplan";

@Options({})
export default class extends Vue {
  private progressDetail: any = {};
  private planId: any = null;

  approveStatusFilter(val: any) {
    if (val === 0) {
      return "未发起";
    } else if (val === 1) {
      return "发起中";
    } else if (val === 2) {
      return "已关闭";
    }
  }

  protected showTask() {
    this.$router.push({ path: "/app/projectPlanTask", query: { id: this.planId } });
  }

  mounted() {
    this.planId = this.$route.query.id
    api.progressDetail(this.$route.query.id).then((res: any) => {
      if (res.code == 0) {
        this.progressDetail = res.data;
      } else {
        this.$toast.fail(res.msg);
      }
    });
  }
}
